import {createGlobalStyle, css} from 'styled-components'
import {normalize} from 'styled-normalize'
import media from '../../utils/media'
import typography from './typography'


export default createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
  }

  /* stylelint-disable */
  ${normalize}
  /* stylelint-enable */

  :root {
    scroll-behavior: smooth;
    line-height: ${({theme}) => theme.fonts.lineHeight.sm};
    font-family: ${({theme}) => theme.fonts.family.primary}, sans-serif;
    font-size: ${({theme}) => theme.responsive.zoom.fontSizeMobile}px;
    ${media.up('mobile')(css`
      font-size: ${({theme}) => theme.responsive.zoom.fontSizeDesktop}px;
    `)}
  }

  body {
    background-color: ${({theme}) => theme.colors.background};
    color: ${({theme}) => theme.colors.text};
    font-size: 1rem;
  }

  a {
    text-decoration: none;
    color: ${({theme}) => theme.colors.text};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h2 {
    margin-top: 3rem;
  }

  p:not(:first-child) {
    margin-block-start: ${({theme}) => theme.spacer};
    margin-block-end: 0;
  }

  ul {
    padding: 0 0 0 0.9rem;

    li {
      padding-top: 0.5rem;
    }
  }

  details > summary {
    list-style: none;
  }

  details > summary::marker, /* Latest Chrome, Edge, Firefox */
  details > summary::-webkit-details-marker /* Safari */ {
    display: none;
  }

  /* stylelint-disable */
  ${typography}
  /* stylelint-enable */
`
