import PropTypes from 'prop-types'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'
import GlobalStyles from './GlobalStyles'
import GoogleFont from './GoogleFont'
import theme from './theme'


const ThemeProvider = ({children, ...props}) => (
  <StyledThemeProvider theme={theme} {...props}>
    <GlobalStyles />
    <GoogleFont query="family=Josefin+Sans:wght@400;700&display=swap" />
    {children}
  </StyledThemeProvider>
)

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProvider
