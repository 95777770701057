import {transparentize} from 'polished'

// Fonts
const FONT_SIZE_BASE = 18
const FONT_SIZE_DESKTOP = 16
const FONT_SIZE_MOBILE = 14

// Colors
const WHITE = '#FFFFFF'
const ORANGE = '#F57E2B'
const BLUE_GRAY = '#5B6676'
const LIGHT_GRAY = '#48515E'
const DARK_GRAY = '#3C434D'

const GRADIENT_FROM = transparentize(0.4, DARK_GRAY)

export const px2rem = (size, base = FONT_SIZE_BASE) => `${size / base}rem`

const theme = {
  spacer: '1rem',
  colors: {
    primary: ORANGE,
    secondary: WHITE,
    text: WHITE,
    background: DARK_GRAY,
    background2: LIGHT_GRAY,
    aux: BLUE_GRAY,
  },
  fonts: {
    family: {
      primary: 'Josefin Sans',
    },
    size: {
      sm: '1rem',
      md: '1.25rem',
      lg: '1.5rem',
    },
    weight: {
      regular: '400',
      bold: '700',
    },
    lineHeight: {
      sm: '1.5',
      md: '1.5',
      lg: '1.5',
    },
    fontStyle: {
      normal: 'normal',
      italic: 'italic',
    },
  },
  responsive: {
    breakpoints: {
      mobile: 768,
      desktop: 1440,
    },
    zoom: {
      minimalViewport: 320,
      maximalViewport: 1440,
      designedViewport: 1920,
      fontSizeBase: FONT_SIZE_BASE,
      fontSizeDesktop: FONT_SIZE_DESKTOP,
      fontSizeMobile: FONT_SIZE_MOBILE,
    },
    container: {
      desktop: px2rem(700),
      mobile: px2rem(720, FONT_SIZE_MOBILE),
    },
  },
  overlays: {
    background: transparentize(0.2, DARK_GRAY),
    gradient: `0deg, ${transparentize(0.1, DARK_GRAY)} 80%, ${GRADIENT_FROM} 90%, transparent 100%`,
    homepageGradient: `0deg, ${DARK_GRAY} 50%, ${GRADIENT_FROM} 60%, transparent 80%`,
  },
  animation: '.2s ease-in',
  border: {
    default: '1px solid',
    radius: {
      sm: '0.5rem',
      md: '2rem',
      lg: '50%',
    },
  },
}

export default theme
