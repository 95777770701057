import {css} from 'styled-components'


export const body = css`
  font-family: ${({theme}) => theme.fonts.family.primary};
`

export const h1 = css`
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.primary};
  font-family: ${({theme}) => theme.fonts.family.primary};
  font-size: ${({theme}) => theme.fonts.size.lg};

  span {
    color: ${({theme}) => theme.colors.text};
  }
`

export const h1Alternate = css`
  text-transform: unset;
  text-decoration: underline;
  color: ${({theme}) => theme.colors.primary};
  font-family: ${({theme}) => theme.fonts.family.primary};
  font-size: ${({theme}) => theme.fonts.size.lg};
`

export const h2 = css`
  color: ${({theme}) => theme.colors.primary};
  font-family: ${({theme}) => theme.fonts.family.primary};
  font-size: ${({theme}) => theme.fonts.size.md};

  strong {
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.primary};
  }
`

export const h3 = css`
  color: ${({theme}) => theme.colors.text};
  font-family: ${({theme}) => theme.fonts.family.primary};
  font-size: ${({theme}) => theme.fonts.size.sm};
  font-weight: bold;
`

export const button = css`
  color: ${({theme}) => theme.colors.text};
  font-family: ${({theme}) => theme.fonts.family.primary};
  font-size: ${({theme}) => theme.fonts.size.sm};
  font-weight: bold;
`

export const label = css`
  font-family: ${({theme}) => theme.fonts.family.primary};
`

export default css`
  body {
    ${body}
  }

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  button {
    ${button}
  }

  input,
  label {
    font-family: ${({theme}) => theme.fonts.family.primary};
  }

  strong {
    font-weight: ${({theme}) => theme.fonts.weight.bold};
  }

  i {
    font-style: ${({theme}) => theme.fonts.fontStyle.italic};
  }

  .sr-only {
    position: absolute;
    top: -9999px;
    left: -9999px;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
  }
`
