exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-subscription-annually-js": () => import("./../../../src/pages/subscription/annually.js" /* webpackChunkName: "component---src-pages-subscription-annually-js" */),
  "component---src-pages-subscription-cancelation-js": () => import("./../../../src/pages/subscription/cancelation.js" /* webpackChunkName: "component---src-pages-subscription-cancelation-js" */),
  "component---src-pages-subscription-gift-annually-js": () => import("./../../../src/pages/subscription/gift/annually.js" /* webpackChunkName: "component---src-pages-subscription-gift-annually-js" */),
  "component---src-pages-subscription-gift-index-js": () => import("./../../../src/pages/subscription/gift/index.js" /* webpackChunkName: "component---src-pages-subscription-gift-index-js" */),
  "component---src-pages-subscription-gift-once-js": () => import("./../../../src/pages/subscription/gift/once.js" /* webpackChunkName: "component---src-pages-subscription-gift-once-js" */),
  "component---src-pages-subscription-gift-quaterly-js": () => import("./../../../src/pages/subscription/gift/quaterly.js" /* webpackChunkName: "component---src-pages-subscription-gift-quaterly-js" */),
  "component---src-pages-subscription-gift-semi-annually-js": () => import("./../../../src/pages/subscription/gift/semi-annually.js" /* webpackChunkName: "component---src-pages-subscription-gift-semi-annually-js" */),
  "component---src-pages-subscription-index-js": () => import("./../../../src/pages/subscription/index.js" /* webpackChunkName: "component---src-pages-subscription-index-js" */),
  "component---src-pages-subscription-once-js": () => import("./../../../src/pages/subscription/once.js" /* webpackChunkName: "component---src-pages-subscription-once-js" */),
  "component---src-pages-subscription-quaterly-js": () => import("./../../../src/pages/subscription/quaterly.js" /* webpackChunkName: "component---src-pages-subscription-quaterly-js" */),
  "component---src-pages-subscription-renewal-js": () => import("./../../../src/pages/subscription/renewal.js" /* webpackChunkName: "component---src-pages-subscription-renewal-js" */),
  "component---src-pages-subscription-semi-annually-js": () => import("./../../../src/pages/subscription/semi-annually.js" /* webpackChunkName: "component---src-pages-subscription-semi-annually-js" */),
  "component---src-templates-about-author-js": () => import("./../../../src/templates/aboutAuthor.js" /* webpackChunkName: "component---src-templates-about-author-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-community-js": () => import("./../../../src/templates/community.js" /* webpackChunkName: "component---src-templates-community-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-photo-js": () => import("./../../../src/templates/photo.js" /* webpackChunkName: "component---src-templates-photo-js" */)
}

