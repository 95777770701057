import ThemeProvider from '../uikit/theme/ThemeProvider'


export const wrapPageElement = ({element}) => {
  return (
    <ThemeProvider>
      {element}
    </ThemeProvider>
  )
}
