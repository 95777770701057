import {memo, useEffect} from 'react'
import {isNull} from 'lodash-es'
import {Helmet} from 'react-helmet'
import PropTypes from 'prop-types'
import {setCookie} from '../../utils/cookies'


const FONTS = 'fonts'
const SITE_VISITED = 'siteVisited'

const loadFonts = (fontCss) => (`
  if (window.document.cookie.includes("${SITE_VISITED}")) {
    const preloadLink = window.document.createElement('link')
    preloadLink.id = "${FONTS}"
    preloadLink.href = "${fontCss}"
    preloadLink.rel = 'stylesheet'
    window.document.head.appendChild(preloadLink)
  }
`)

const GoogleFont = ({query}) => {
  const fontCss = `https://fonts.googleapis.com/css2?${query}`

  useEffect(() => {
    setCookie(SITE_VISITED, true, 30, '/')
    if (typeof window !== 'undefined' && isNull(window.document.getElementById(FONTS))) {
      const preloadLink = window.document.createElement('link')
      preloadLink.href = fontCss
      preloadLink.rel = 'stylesheet'
      window.document.head.appendChild(preloadLink)
    }
  }, [fontCss])

  return (
    <Helmet>
      <script>{loadFonts(fontCss)}</script>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link rel="preload" as="style" href={fontCss} />
      <noscript>
        {`<link rel="stylesheet" href="${fontCss}" />`}
      </noscript>
    </Helmet>
  )
}

GoogleFont.propTypes = {
  query: PropTypes.string.isRequired,
}

export default memo(GoogleFont)
